.c-nav__link {

	@include critical {
		@include fontBold();

		@include mq-min($bpDesktop) {
			font-size: rem(15.52px);
			letter-spacing: 0.3px;
			@include fontMedium();
		}
	}
}

@include critical {
	@include mq-min($bpDesktop) {
		.c-nav__item {
			max-width: none;
		}
	}
}

.c-nav__meganav-list-link,
.c-nav__meganav-title-link {

	.no-touch &:hover {

		@include defer {

			@include mq-min($bpDesktop) {
				color: $colorBase;
			}
		}
	}
}

.c-nav__meganav-title {

	@include defer {
		@include fontBold();

		@include mq-min($bpDesktop) {
			font-size: rem(20px);
			letter-spacing: 0.2px;
		}
	}
}

.c-nav__meganav-list-title {

	@include defer {
		@include fontBold();

		@include mq-min($bpDesktop) {
			font-size: rem(16px);
			letter-spacing: 0.2px;
		}
	}
}

.c-nav__meganav-list {

	@include defer {

		@include mq-min($bpDesktop) {
			font-size: rem(16px);
			letter-spacing: 0.2px;
		}
	}
}

.c-nav__meganav-title-link:after {
	@include defer {
		display: none;	
	}
}
