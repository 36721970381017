﻿@include defer {

	.mCalculator {
		.formButton {

			@include mq-min($bpDesktop) {
				@include fontMedium();
			}
		}
	}

	.mCalculator label,
	.mCalculator__form__title,
	.mCalculator__form__radios__item label,
	.mCalculator__form__results__title,
	.mCalculator__form__results__subtitle,
	.mCalculator__form__results__item {
		@include fontBold();
	}
}
