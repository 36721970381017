.c-header {

	@include critical {
		z-index: auto;

		@include mq-min($bpDesktop) {
			padding-top: 50px;

			&.c-header--checkout {
				padding-top: 0;
			}
		}
	}
}


.c-header.is-sticky {
	@include defer {
		padding-top: 0;
	}
}

.c-header__logo {

	@include critical {
		width: 150px;
		margin: 0;
		position: relative;
		top: -4px;

		@include mq-min(375px) {
			width: 150px;
		}

		@include mq-min($bpDesktop) {
			width: 257px;
			margin: 0 auto;
			padding: 0 0 20px 0;
			position: static;
			top: -7px;
		}

		.is-trade & {
			top: initial;
			@include mq-min($bpDesktop) {
				padding: 10px 0;
			}
		}

		.c-header--checkout & {
			margin: 0 auto;

			@include mq-min($bpDesktop) {
				width: 257px;
			}
		}
	}
}

.c-header__utils__link {

	@include critical {
		position: relative;
		width: 30px;
		height: 30px;
		display: flex;
		flex-direction: column;
		align-items: center;
		@include fontMedium();
		font-size: rem(14px);
		line-height: rem(14px);

		@include mq-min($bpDesktop) {
			width: fit-content;
			height: fit-content;
		}
	}

	@include defer {

		&:hover,
		&:focus {
			opacity: 1;

			.c-header__utils__count {
				border-color: $colorBeta;
				color: $colorBeta;
			}
		}
	}
}

.c-header__utils__item--account,
.c-header__utils__item--wishlist {
	.c-header__utils__link {
		@include critical {
			@include mq-min($bpDesktop) {
					display: block;
				}
			}
		}
	}

	.c-header__utils__count {

	@include critical {
		display: flex;
		align-content: center;
		justify-content: center;
		text-align: center;
		top: 0px;
		right: -7px;
		width: 19px;
		height: 19px;
		border: 1px solid $colorBase;
		border-radius: 100%;
		line-height: rem(19px);
		font-size: rem(12px);
		color: $colorBase;
		transform: none;
		background: $colorWhite;
		padding: 0;
		@include fontBold();

		@include mq-min($bpDesktop) {
			top: 1px;
			right: 0px;
		}
	}
}

	.c-header__utils__count__text {
		@include critical {
			line-height: rem(19px);
		}
	}

	.c-header__logo__link,
	.c-header__search__button,
	.c-header__utils__link,
	.c-header__utils__sub__link,
	.c-header__search-toggle__link {

		@include defer {
			transition: opacity 0.25s ease;
			will-change: opacity;

			&:hover,
			&:focus {
				color: $overrideHeaderGenericLinkHoverFocusColor;
			}
		}
	}

	.c-header__utils__sub__link {

		&:hover,
		&:focus {

			@include defer {
				color: $overrideHeaderUtilsSubLinkHoverFocusColor;
			}
		}
	}

	.c-header__utils__sub {

		@include defer {
			font-size: rem(13px);
			@include fontMedium();
		}
	}

	.c-header__search {

		@include critical {
			transform: none;
			margin-top: -22.5px;
		}
	}

	.c-header__search__inner {

		@include critical {
			height: 45px;

			@include mq-min($bpMassive + 100) {
				width: 430px;
			}

			.br-typeahead {
				display: block !important;
			}
		}
	}

	.c-header__search__text {

		@include critical {
			height: 45px;
			border: 0;
			padding: 0 20px;
			@include fontRegular();
			color: $colorBase;
			font-size: rem(14px);
			width: 100%;
			padding-right: 60px;
			@include box-sizing();

			@include mq-max($bpMega - 1) {
				padding-left: 10px;
			}

			@include mq-min($bpMassive + 100) {
				font-size: rem(16px);
			}
		}

		&::placeholder,
		&:-ms-input-placeholder,
		&::-ms-input-placeholder {

			@include defer {
				opacity: 1;
				color: $colorBase;
			}
		}
	}

	.c-header__search__button {

		@include critical {
			height: 45px;
			width: 60px;

			.c-icon {
				width: 26px;
				height: 26px;
			}
		}
	}

	.c-header__utils__icon {

		@include critical {
			display: block;
			margin: 0 auto;
		}
	}

	.c-header__utils__text {

		@include critical {
			display: block;
			margin: 8px 0 0 0;

			@include mq-max($bpDesktop - 1) {
				display: none;
			}
		}
	}

	.c-header__utils__item {

		@include critical {

		&:not(:first-child) {
			margin-left: 15px;

				@include mq-min(1400px) {
					margin-left: 30px;
				}
			}
		}

		@include mq-min($bpDesktop) {
			align-items: center;
		}
	}

	.c-header__utils__item--help {

		@include mq-max($bpDesktop - 1) {
			display: none;
		}

		.c-header__utils__icon {

			@include critical {
				width: 28.236px;
				height: 30.656px;
			}
		}

		.c-header__utils__text {
			@include critical {
				margin: 5.5px 0 0 0;
			}
		}
	}

	.c-header__utils__item--trade {

		@include critical {

			@include mq-max($bpDesktop - 1) {
				display: none;
			}
		}

		.c-header__utils__icon {

			@include critical {
				width: 25.161px;
				height: 28px;
			}
		}
	}

	.c-header__utils__item--design {

		@include critical {

			@include mq-max($bpDesktop - 1) {
				display: none;
			}
		}

		.c-header__utils__icon {

			@include critical {
				width: 28px;
				height: 28px;
			}
		}
	}

	.c-header__utils__item--wishlist {

		@include critical {
			position: relative;
			padding-right: 0;
			text-align: center;
		}

		.c-icon {

			@include critical {
				margin-top: -2px;

				@include mq-min($bpDesktop) {
					width: 30px;
					height: 30px;
					margin: 0;
				}
			}
		}
	}

	.c-header__utils__item--account {
		@include mq-min($bpDesktop) {
			.c-header__utils__icon {

				@include critical {
					width: 29px;
					height: 29px;
					margin: 0 auto;
				}
			}
		}
	}

	.c-header__utils__item--basket {

		@include critical {
			position: relative;

			.c-header__utils__icon {		
				width: 37px;
				height: 37px;
				margin-top: -3px;

				@include mq-min($bpDesktop) {
					width: 35px;
					height: 29px;
					margin: 0;
				}
			}
		}
	}

	.c-header__utils__item--basket-populated {

		.c-header__utils__link {

			@include defer {
				color: $overrideHeaderUtilsLinkPopulatedColor;

				.c-header__utils__count {
					border-color: $colorBeta;
					color: $colorBeta;
				}
			}
		}
	}

	.c-header__middle__inner {

		@include critical {

			@include mq-min($bpDesktop) {
				padding: 10px 20px;
			}
		}
	}

	.c-header__utils__text__hide-middle {
		@include critical {

			@include mq-min($bpDesktop) {
				@include mq-max(1250px) {
					display: none;
				}
			}
		}
	}
