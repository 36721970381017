﻿.m-latest-post__title__link {

	@include critical {
		font-weight: normal;
	}
}

.m-latest-post__meta {

	a {

		@include critical {
			@include fontBold();
			text-decoration: underline;
		}

		&:hover,
		&:focus {

			@include defer {
				color: $colorAlpha;
			}
		}
	}
}