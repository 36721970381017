﻿@include defer {

	.br-typeahead .tt-suggestion > .product > .content > .price,
	.br-typeahead .tt-highlight {
		@include fontBold();
	}

	.br-typeahead .tt-suggestion > .product > .content .price__was {
		@include fontMedium();
	}

	.br-typeahead .tt-header .title {
		color: $colorBase;
	}

	.br-typeahead .tt-highlight {
		color: $colorBase;
	}

	.br-typeahead .tt-suggestion {
		line-height: rem(24px);
	}

	.tt-header > p.summary > a {
		text-decoration: underline;
	}

	.tt-header > p.summary > a:hover {
		text-decoration: none;
	}

	.br-typeahead .tt-footer > .link {
		@include fontMedium();
	}

	.tt-header__link-arrow {
		display: none;
	}

	.br-typeahead .tt-suggestion > .product > picture > .image {
		border: none;
	}
}
