@mixin fontRegular() {
	font-family: 'BrandonTextWeb-Regular', 'BrandonTextWeb-Regular Fallback', 'BrandonTextWeb-Regular Fallback Android', Arial, Helvetica, Verdana, sans-serif;
	font-weight: normal !important;
	font-style: normal !important;
}

@mixin fontMedium() {
	font-family: 'BrandonTextWeb-Medium', 'BrandonTextWeb-Medium Fallback', 'BrandonTextWeb-Medium Fallback Android', Arial, Verdana, Helvetica, sans-serif;
	font-weight: normal !important;
	font-style: normal !important;
}

@mixin fontBold() {
	font-family: 'BrandonTextWeb-Bold', 'BrandonTextWeb-Bold Fallback', 'BrandonTextWeb-Bold Fallback Android', Arial, Verdana, Helvetica, sans-serif;
	font-weight: normal !important;
	font-style: normal !important;
}

@mixin fontSerif() {
	font-family: 'DM Serif Display', 'DM Serif Display Fallback', 'DM Serif Display Fallback Android', Georgia, serif;
	font-weight: normal !important;
	font-style: normal !important;
}
