@include defer {

	.c-footer {
		@include fontRegular();

		&.c-footer--checkout {
			min-height: 400px;
			background: $colorWhite;

			a {
				color: $colorBase;
				transition: color 300ms;

				&:hover,
				&:focus {
					color: #5095A0;
				}
			}
		}
	}

	.c-footer__title {
		@include fontBold();
	}

	.c-footer__contact-link,
	.c-footer__inline-list-link,
	.c-footer__copyright a {

		&:hover,
		&:focus {
			color: #5095A0;
		}
	}

	.c-footer__logo-wrapper {
		max-width: 223px;
	}

	.c-footer__logo-wrapper__inner {
		margin: 0 0 30px 0;
		@include ratio(223, 53.439);
		position: relative;

		.is-trade & {
			@include ratio(313.69, 87.05);
		}

		@include mq-min($bpMassive) {
			margin: 0 0 40px 0;
		}
	}

	.c-footer__social-list {

		@include mq-max($bpLarge - 1) {
			padding: 0 0 25px 0;
		}
	}

	.c-footer__logo {
		margin: 0;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;

		.c-footer--checkout & {
			position: static;
			width: 200px;
			height: auto;
		}
	}

	.c-newsletter-signup__input {
		@include fontRegular();
		font-size: rem(14px);
		padding: 13px 12px;
	}

	.c-newsletter-signup__button {
		@include fontBold();
		font-size: rem(14px);
	}

	.c-footer__inline-list {
		font-size: rem(13px);
		line-height: rem(17px);
	}

	.c-footer__inline-list-item:not(.c-footer__inline-list-item--with-image) {

		&:last-child {
			margin-right: 0;

			.c-footer__inline-list-link {
				padding-right: 0;
			}
		}
	}

	.c-footer__copyright {
		font-size: rem(11px);
		line-height: rem(15px);

		@include mq-max($bpDesktop - 1) {
			padding: 0;
		}
	}

	.c-footer__contact-wrapper {

		@include mq-min($bpMassive) {
			display: flex;
		}

		.c-footer__inline-list {

			@include mq-min($bpTablet) {
				margin: 0;
			}

			@include mq-min($bpExMega) {
				position: relative;
				top: 5px;
			}
		}
	}

	.c-footer__contact {
		margin: 0 0 15px 0;
		color: $colorBase;
		font-size: rem(18px);
		line-height: rem(21px);

		@include mq-min($bpMassive) {
			min-width: 0;
			margin: 0 35px 0 0;
		}
	}

	.c-footer__contact-item {
		margin: 0;

		&:not(:first-child) {
			padding: 0;
		}
	}
}
