.c-product-page__title,
.c-product-page__need-help {
	@include critical {
		font-weight: 400;
	}
}

.c-product-main-price {
	@include critical {
		@include fontBold();
	}
}

.c-product-page__subtitle {
	@include critical {
		font-weight: 400;
	}
}

.c-product-page__link {
	@include critical {
		@include fontMedium();
	}
}

.c-product-page__need-help-call {
	@include critical {
		font-weight: 400;
	}
}

@include defer {
	.c-product-page__variants__item__label {
		font-size: rem(16px);
		@include fontMedium();
	}
}

.c-product-page__overlay {
	@include critical {
		top: 0;
		left: 0;
	}
}
