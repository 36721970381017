﻿@include critical {

	.js-tooltip-trigger {

		&:before {
			@include fontBold();

			@include mq-min($bpDesktop) {
				margin-top: -2px;
			}
		}
	}
}

@include defer {

	.js-tooltip__inner {

		.cms {
			p {
				font-size: rem(16px);
			}

			a,
			p:first-child strong {
				@include fontBold();
			}

			p:first-child strong {
				font-size: rem(17px);
			}
		}
	}

	.js-tooltip__close:before,
	.js-tooltip__close:after {
		margin-top: -2px;
	}
}