@include critical {

	.c-product__title {
		@include fontMedium();
	}

	.c-product__title__link {
		font-weight: normal;
	}

	.c-product__variants__item--more {

		.c-product__variants__link {
			@include fontMedium();
			border-width: 2px;
			font-size: rem(14px);

			@include mq-min($bpDesktop) {
				padding: 9px;
			}

			@include mq-max($bpDesktop - 1) {
				font-size: rem(11px);
				padding-top: 11px;
				padding-bottom: 9px;
			}
		}
	}

	.c-product__variants__link__text {
		text-transform: none;
	}

	.c-product__variants__item--more .c-product__variants__link {
		text-decoration: none !important; /* Needs to override the CMS selector */
	}
	/*
		Mobile and tablet overrides
		- Scoped to search and category pages only
	*/
	@include mq-max($bpDesktop - 1) {

		.mRange {

			.c-overlay__letters {
				display: inline-block;
				font-size: rem(12px);
				margin-left: 3px;
			}

			.c-product__price .c-price__main {
				margin: 0 0 3px 0;
			}

		}
	}

	.c-product__overlay {
		top: -1px;
		left: -1px;
	}

	.c-article .c-product {
		.c-product__overlay {
			top: 0;
			left: 0;
		}
	}

	.c-product--sticky-floater .c-product__overlay {
		top: 0;
		left: 0;
	}
}

@include defer {

	.c-product__variants__item--more {

		.c-product__variants__link {

			&:hover,
			&:focus {
				border-color: $overrideProductMoreLinkBorderColor;
			}
		}
	}

	.c-product__title__link {

		&:hover,
		&:focus {
			color: $colorBase;
		}
	}
}
