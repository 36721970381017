@include defer {

	.atb__main__added {
		@include fontMedium();
	}

	.atb__main__added__message {

		@include mq-min($mAtbDesktopBp) {
			@include fontBold();
		}
	}

	.atb__main__added__title {

		@include mq-min($mAtbDesktopBp) {
			font-weight: normal;
		}
	}

	.atb__main__added__title__price {

		@include mq-min($mAtbDesktopBp) {
			@include fontBold();
		}
	}

	.atb__main__product__title {
		font-size: rem(16px);
		line-height: rem(24px);
	}

	.atb__main__header__message,
	.atb__main__title,
	.atb__main__product__title,
	.atb__main__footer__close,
	.atb__detail__item__title,
	.atb__detail__item__features,
	.atb__detail__item__specs {
		@include fontMedium();
	}

	.atb__main__footer__close {
		text-transform: capitalize;
	}

	.atb__main__footer__basket__button__basket-text {
		text-transform: capitalize;
	}
	// Note: to line up buttons to within 1px of where they should be in Safari
	a.atb__quantity__add {
		padding-top: 10px;
		padding-bottom: 0;
		line-height: rem(16px);
	}

	.atb__main__footer__basket__button,
	a.atb__main__footer__basket__button {
		font-weight: normal;

		@include mq-min($mAtbDesktopBp) {
			min-width: 200px;
		}
	}

	.atb__main__added__image {
		border: none;
	}

	.atb {
		.atb__detail__item__title {
			color: #475257;
		}

		.atb__detail__item__features__item {
			color: #475257;
			font-family: 'BrandonTextWeb-Regular', Arial, Helvetica, sans-serif;
		}

		.atb__detail__item__action a { 
			text-decoration: underline;
			font-family: 'BrandonTextWeb-Medium', Arial, Helvetica, sans-serif;
		}
	}
}
