@use "sass:color";

@include defer {

	.mFilterBy {
		@include fontBold();

		@include mq-min($bpDesktop) {
			font-size: rem(17px);
		}

		@include mq-min($bpMassive) {
			font-size: rem(20px);
		}
	}

	.mFilterNextDayDelivery {

		> .inner {

			> .title {
				font-weight: normal;
			}
		}
	}

	.mFilter {

		> .inner {

			> .title {
				font-weight: normal;

				@include mq-max($bpDesktop - 1) {
					padding-right: 55px;
					color: $colorBase;
				}

				@include mq-min($bpDesktop) {
					font-size: rem(17px);
				}
			}
		}
	}

	.mFilter > .inner > .content .formChecks:not(.formChecksImage) .level1 input:checked ~ span {
		font-weight: normal;
	}

	.mFilter > .inner > .content > .mShowMore > .link:after {
		left: 190px;
	}

	.mFilter > .inner > .content > .formChecks > .level1 label small,
	.mFilter > .inner > .content .formRadios > .level1 label small {
		color: $colorBase;
	}

	.mFilter > .inner > .content > .formChecks > .level1 label:hover,
	.mFilter > .inner > .content .formRadios > .level1 label:hover {
		text-decoration: underline;
	}

	.mFilter > .inner > .content > .formChecksImage > .level1 label:hover {
		text-decoration: none;

		&:before {
			content: '';
			width: 100%;
			height: 100%;
			border: 1px solid $colorBase;
			display: block;
			position: absolute;
			left: 0;
			top: 0;
			box-sizing: border-box;
		}
	}

	.mFilter > .inner > .content > .mShowMore > .link {
		color: $colorBase !important; /* Needs to be !important to override */
		@include fontBold();
	}

	.filterSubmitButton {
		background-color: $colorBeta !important; /* Needs to be !important to override */
		color: $colorWhite !important; /* Needs to be !important to override */
		height: auto;
		padding: 10px 15px;
	}

	.filterSubmit {
		height: 82px;
	}

	.filterSubmitButton:hover {
		background-color: color.adjust(#db383b, $lightness: -10%, $space: hsl) !important; /* Needs to be !important to override */
		color: $colorWhite !important; /* Needs to be !important to override */
	}

	.mFilter > .inner > .content > .formChecks > .level1 span:before,
	.mFilter > .inner > .content .formRadios > .level1 span:before {
		border-radius: 0;
		border-color: $colorBase;
		right: 15px;
	}

	.js .mFilter .js-tooltip-trigger {
		vertical-align: baseline;
	}

	@include mq-max($bpDesktop - 1) {

		.filterContainer {
			margin-right: 0;
			width: auto;
			right: 0;
		}

		.filterContent {
			padding: 0 35px 0 15px;
		}

		.filterCloseContainer__close .filterCloseContainer__reset-link,
		.filterCloseContainer__reset .filterCloseContainer__reset-link {
			padding-right: 35px;
			padding-top: 24px;
		}

		body .filterCloseContainer__close .filterCloseContainer__close-link {
			font-size: rem(12px);
		}

		.filterSubmit {
			width: auto;
			right: 10px;
			height: 100px;
		}

		.filterSubmitButton {
			height: 50px;
		}

		a.filterSubmitButton {
			padding-top: 0;
			padding-bottom: 0;
		}

		.mFilter > .inner > .content > .formChecks > .level1,
		.mFilter > .inner > .content .formRadios > .level1 {
			padding-left: 15px
		}

		.mFilterNextDayDelivery > .inner > .title:before {
			left: 15px;
		}

		.mFilterNextDayDelivery > .inner > .title {
			padding-left: 52px;
		}
	}

	.mFilterClear .primary {
		@include fontMedium();
		border-width: 2px;

		@include mq-min($bpDesktop) {
			font-size: rem(14px);
		}
	}
}

@include defer {

	.filterSubmitButton {

		&:hover,
		&:focus {
			background-color: color.adjust($colorBeta, $lightness: 25%, $space: hsl) !important; /* Needs to be !important to override */
		}
	}
}

.mFilter>.inner>.content>.formChecks>.level1 span,
.mFilter>.inner>.content .formRadios>.level1 span {

	@include mq-min($bpDesktop) {
		font-size: rem(16px);
	}
}
