.c-breadcrumbs,
.c-section .c-breadcrumbs {

	.c-breadcrumbs__link {

		@include critical {
			color: $colorBase;
		}

		&:hover,
		&:focus {

			@include defer {
				color: $colorBase;
			}
		}
	}

	@include critical {
		.c-breadcrumbs__list-item.is-active {
			@include fontMedium();
			color: $colorBase;
		}

		.c-breadcrumbs__list {
			font-size: rem(16px);
			line-height: rem(20px);
		}
	}
}
