@include critical {

	.c-usps {
		text-transform: uppercase;

		@include mq-min($bpDesktop) {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			padding: 0;
			height: 50px;
		}

		@include mq-max($bpDesktop - 1) {
			min-height: 32px;
		}

		@include mq-min($cUSPSInlineBp) {
			display: flex;
			justify-content: center;
		}

		@include mq-min($bpDesktop) {
			@include mq-max($cUSPSInlineBp - 1) {
				.splide__arrows .splide__arrow {
					top: 10px;
				}

				.c-usps__inner {
					height: 34px;
					padding: 8px 0;
				}
			}
		}

		.tns-controls {

			[data-controls] {

				@include mq-min($bpDesktop) {

					@include mq-max($cUSPSInlineBp - 1) {
						top: 7px;

						&:before {
							background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMiIgaGVpZ2h0PSIzMiIgdmlld0JveD0iMCAwIDI0IDI0Ij4gIDxwYXRoIGQ9Ik05LjcwNyAxOC43MDdsNi02YS45OTkuOTk5IDAgMCAwIDAtMS40MTRsLTYtNmEuOTk5Ljk5OSAwIDEgMC0xLjQxNCAxLjQxNEwxMy41ODYgMTJsLTUuMjkzIDUuMjkzYS45OTkuOTk5IDAgMSAwIDEuNDE0IDEuNDE0eiIgZmlsbD0iI2ZmZiIvPjwvc3ZnPg==);
						}
					}
				}
			}
		}
	}

	.c-usps__inner {

		@include mq-min($bpDesktop) {
			width: 550px;
			margin: 0 auto;
			padding: 11px 0 0 0;
		}

		@include mq-min($cUSPSInlineBp) {
			width: 100%;
			padding: 0;
		}
	}

	.c-usps__list {

		@include mq-min($bpDesktop) {
			position: relative;
			padding: 0;
		}

		@include mq-min($cUSPSInlineBp) {
			display: flex;
			padding: 11px 20px;
		}
	}

	.c-usps__list-item {
		padding: 0;
		flex: none;

		&:not(:first-child) {
			@include mq-min($cUSPSInlineBp) {
				margin-left: 15px;
			}

			@include mq-min($cUSPSInlineBp + 50) {
				margin-left: 30px;
			}
		}
	}

	.c-usps__icon {
		margin-right: 10px;
	}

	.c-usps__link {
		font-size: rem(12px);
		transition: opacity 0.5s ease;

		@include mq-min($cUSPSInlineBp) {
			align-items: center;
			font-size: rem(13px);
		}
	}

	.c-usps__link-content {
		line-height: 1.4;
	}

	.c-usps__icon--pound {
		width: 24px;
	}

	.c-usps__title {
		@include fontBold();
		font-size: rem(12px);
		letter-spacing: 0.7px;

		html:not(.all-fonts-loaded) & {
			letter-spacing: 1px !important;
			font-weight: 600 !important;
		}
	}

	.c-usps__title__inner {
		display: block;

		@include mq-min($cUSPSInlineBp) {
			display: inline;
		}
	}
}

@include defer {

	.c-usps__link:hover,
	.c-usps__link:focus {
		opacity: 0.75;
	}
}
